import React from "react";

const Conditions = () => {
    return (
        <div>
            Conditions
        </div>
    );
}

export default Conditions