import React, { useEffect } from "react";

const Reviews = () => {
    useEffect(() => {
        const newTitle = "Отзывы - AVTORENT22";
        const newDescription = "Здесь вы сможете прочитать отзывы о нас";

        // Устанавливаем новый заголовок страницы, если он отличается от текущего
        if (document.title !== newTitle) {
            document.title = newTitle;
        }

        // Находим мета-тег с именем "description" и устанавливаем его содержимое
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", newDescription);
        }
    }, []);

    return (
        <div>
        <h2 className="mb-5 my-4" align="center">Отзывы</h2>
            <div class="sw-app" data-app="50c5c0c9f6c7823001fe42e91a6e2fa5"></div>
        </div>
    );
}

export default Reviews