import 'bootstrap/dist/css/bootstrap.min.css';
import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserStore from "./store/UserStore";
import CarStore from "./store/CarStore"
import TruckStore from "./store/TruckStore";


export const Context = createContext(null)

const script = document.createElement('script');
script.src = "https://res.smartwidgets.ru/app.js";
script.defer = true;

document.head.appendChild(script);


ReactDOM.render(

    <Context.Provider value={{
        user: new UserStore(),
        car: new CarStore(),
        truck: new TruckStore()
    }}>
        <App />
    </Context.Provider>,
    document.getElementById('root')
);




