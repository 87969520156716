import React, { useContext, useEffect } from "react";
import TruckList from "../components/TruckList";
import { fetchTruckBrands, fetchTrucks, fetchTruckTypes } from "../http/truckAPI";
import { Context } from "../index";
import { Card, Col, Container, Image } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import car2 from "../assets/коллажгруз.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeadset,
    faShieldAlt,
    faTruckFront
} from "@fortawesome/free-solid-svg-icons";

const TrucksShop = () => {
    const { truck } = useContext(Context)

    useEffect(() => {
        fetchTruckTypes().then(data => truck.setTruckTypes(data))
        fetchTruckBrands().then(data => truck.setTruckBrands(data))
        fetchTrucks(truck.selectedTruckType.id, truck.selectedTruckBrand.id, truck.page, truck.truck_img, 24).then(data => {
            truck.setTrucks(data.rows)
            truck.setTruckTotalCount(data.count)
            truck.setTruckImg(data.truck_img);
        })
    }, [ truck.page, truck.selectedTruckType, truck.selectedTruckBrand, truck.truck_img ])
    useEffect(() => {
        const newTitle = "Услуги грузовых авто и спецтехники в Барнауле – AVTORENT22";
        const newDescription = "У нас вы сможете оформить услуги грузовых авто и спецтехники в Барнауле по выгодной цене";

        // Устанавливаем новый заголовок страницы, если он отличается от текущего
        if (document.title !== newTitle) {
            document.title = newTitle;
        }

        // Находим мета-тег с именем "description" и устанавливаем его содержимое
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", newDescription);
        }
    }, []);
    return (
        <Container fluid className='razd'>
            <Row className="justify-content-center align-self-center preim2">
                <h1 style={{ color: "white" }}>Услуги грузовых авто и спецтехники</h1>
            </Row>
            <div className="my-4">
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={12} className='cat'>
                            <TruckList />
                        </Col>
                        <hr />
                        <Col md={5} className='align-self-center'>
                            <Image src={car2} width={'100%'} style={{ borderRadius: "10px" }} />
                        </Col>
                        <Col md={6}>
                            <h2 className="mb-5 my-4" >Услуги грузовых авто и спецтехники в Барнауле</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                <b>Avtorent22</b> предоставляет широкий спектр услуг по аренде грузовых автомобилей и спецтехники в городе Барнаул. Мы занимаемся предоставлением качественной и надежной техники для выполнения разнообразных задач – от строительства и благоустройства до перевозки крупногабаритных грузов. В нашем автопарке вы найдете манипуляторы, экскаваторы-погрузчики, а также услуги по перевозке негабаритных грузов.
                                <br />
                                <h3 className="mb-5 my-4">Услуги</h3>
                                <li><b>Аренда манипулятора:</b> идеальный выбор для подъема и транспортировки различных грузов на стройплощадки или для установки оборудования. Эффективное решение для работы в ограниченном пространстве.</li>
                                <li><b>Аренда экскаватора-погрузчика:</b> универсальная техника для выполнения земляных работ, а также загрузки и выгрузки материалов. Подходит как для крупных строительных проектов, так и для мелких коммунальных задач.</li>
                                <li><b>Перевозка негабаритных грузов:</b> специализированные услуги по перевозке крупногабаритных и тяжеловесных грузов, требующие особого подхода и точного планирования маршрута.</li>
                            </p>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md={11}>
                            <h2 className="mb-5 my-4">Преимущества работы с нами</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                <li><b>Опыт и профессионализм:</b> наша команда состоит из высококвалифицированных специалистов с глубокими знаниями и многолетним опытом работы.</li>
                                <li><b>Широкий ассортимент техники</b> мы предлагаем разнообразный парк техники, который постоянно обновляется и расширяется.</li>
                                <li><b>Гибкие условия аренды:</b> мы предлагаем выгодные и гибкие условия аренды для каждого клиента, учитывая индивидуальные потребности и задачи.</li>
                                <li><b>Конкурентоспособные цены: </b> наши услуги предлагаются по конкурентоспособным и прозрачным ценам без скрытых платежей и комиссий.</li>
                                <li><b>Поддержка и консультации: </b> наши специалисты всегда готовы предоставить профессиональную консультацию и поддержку на каждом этапе сотрудничества.</li>
                            </p>
                            <h2 className="mb-5 my-4">Как арендовать</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                Арендовать грузовую авто или спецтехнику в Барнауле от компании Avtorent22 легко и просто. Для этого необходимо:
                                <br/>
                                <ol>
                                    <li>Выбрать необходимую технику или услугу на нашем сайте.</li>
                                    <li>Связаться с нами по телефону или через форму обратной связи для уточнения всех деталей и условий аренды.</li>
                                    <li>Подписать договор аренды и получить технику в удобное для вас время.</li>
                                </ol>
                                <br />
                                Мы гарантируем оперативность и высокое качество предоставляемых услуг.
                            </p>
                            <h2 className="mb-5 my-4">Контактная информация</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                <li><b>Телефон:</b> +7 (3852) 99-33-88, +7 (905) 926-33-88</li>
                                <li><b>Email:</b> avtorent22@gmail.com</li>
                                <li><b>Адрес: </b> г. Барнаул, Георгия Исакова 116 б, к. </li>
                                <br />
                                Мы всегда рады помочь вам с выбором и предоставлением необходимой техники для ваших задач. Обращайтесь в Авторент22 за надежной арендой грузовых авто и спецтехники в Барнауле!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Row className="justify-content-center preim1">
                <h2 className="text-center mb-5 prem">Наши премущества</h2>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faHeadset} size="3x" className="mb-3 mt-3 preimicon preimicon1" />
                        <Card.Body>
                            <Card.Title>Мы всегда на связи</Card.Title>
                            <Card.Text>
                                Наша служба поддержки работает круглосуточно, чтобы ответить на все ваши вопросы и решить проблемы.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faTruckFront} size="3x" className="mb-3 mt-3 preimicon preimicon1" />
                        <Card.Body>
                            <Card.Title>Большой ассортимент предоставляемых услуг</Card.Title>
                            <Card.Text>
                                У нас вы можете выбрать любую услугу из обширного списка, чтобы удовлетворить все ваши потребности.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faShieldAlt} size="3x" className="mb-3 mt-3 preimicon preimicon1" />
                        <Card.Body>
                            <Card.Title>Полная гарантия на услугу</Card.Title>
                            <Card.Text>
                                Мы гарантируем быстрое и качественное предоставление услуг
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default TrucksShop