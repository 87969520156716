export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const MAIN_ROUTE = '/'
export const CAR_ROUTE = '/car'
export const CARRENT_ROUTE = '/cars'
export const TRUCK_ROUTE = '/truck'
export const TRUCKRENT_ROUTE = '/trucks'
export const CONDITIONS_ROUTE = '/conditions'
export const CONTACTS_ROUTE = '/contacts'
export const REVIEWS_ROUTE = '/reviews'



