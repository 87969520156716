import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "react-phone-input-2/lib/style.css";
import '../styles/carpage.css'

const ManipulatorPage = () => {

    return (
        <Container>
            <Row className='justify-content-center'>
                <Col md={11}>

                    <p style={{ fontSize: "1.2em" }}>
                        Добро пожаловать в Avtorent22 – вашего надежного партнера в Барнауле для услуг самогруза и манипулятора! Наша команда предлагает профессиональные и доступные решения для перевозки и подъема грузов любой сложности. Современный автопарк и опытные операторы гарантируют высокое качество обслуживания и безопасность на каждом этапе работы.
                    </p>
                    <h2 className="mb-5 my-4">Почему выбирают нас?</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        <li><b>Опыт и профессионализм:</b> наша команда состоит из квалифицированных специалистов с многолетним опытом работы.</li>
                        <li><b>Современный автопарк:</b> мы располагаем современным парком самогрузов и манипуляторов, способных справиться с грузами различной сложности.</li>
                        <li><b>Безопасность и надежность:</b>безопасность вашего груза – наш приоритет. Мы тщательно следим за состоянием техники и обучаем персонал.</li>
                        <li><b>Гибкие условия:</b> мы предлагаем гибкие условия сотрудничества, индивидуальный подход к каждому клиенту и конкурентоспособные цены.</li>
                    </p>
                    <h2 className="mb-5 my-4">Наши услуги</h2>
                    <p style={{ fontSize: "1.2em" }}> 
                        <li><b>Перевозка строительных материалов:</b> быстро и аккуратно доставим строительные материалы на ваш объект.</li>
                        <li><b>Транспортировка оборудования: </b> ответственно перевезем любое оборудование, обеспечив его сохранность.</li>
                        <li><b>Работы на стройплощадке: </b> наши манипуляторы готовы выполнить подъем и установку грузов на вашей стройплощадке.</li>
                        <li><b>Услуги для частных лиц: </b> перевозка мебели, техники и других грузов для частных лиц с полным сопровождением.</li>
                    </p>
                    <h2 className="mb-5 my-4">Наши преимущества</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        <li><b>Индивидуальный подход: </b> мы всегда идем навстречу нашим клиентам, предлагая решения, максимально соответствующие их потребностям.</li>
                        <li><b>Точность и пунктуальность: </b>  мы ценим ваше время и всегда стремимся выполнять работы в срок.</li>
                        <li><b>Качество и безопасность:</b> все операции с грузом проводятся с соблюдением строгих стандартов безопасности.</li>
                    </p>
                    <h2 className="mb-5 my-4">Как заказать услугу?</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        Заказать услуги самогруза и манипулятора в Барнауле очень просто – достаточно связаться с нами по телефону или через форму на сайте. Наши менеджеры проконсультируют вас по всем вопросам и помогут подобрать оптимальный вариант услуги.
                    </p>
                    <h2 className="mb-5 my-4">Свяжитесь с нами</h2>
                    <p style={{ fontSize: "1.2em" }}> 
                        В Avtorent22 мы гордимся тем, что можем предложить жителям и бизнесу Барнаула высококачественные услуги самогруза и манипулятора. Наши профессионалы готовы выполнить работу любой сложности эффективно и в срок. Свяжитесь с нами сегодня, чтобы обсудить детали вашего проекта!
                    </p>

                </Col>
            </Row>
        </Container>
    );
};

export default ManipulatorPage;
