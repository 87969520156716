import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "react-phone-input-2/lib/style.css";
import '../styles/carpage.css'

const OversizedCargoPage = () => {

    return (
        <Container>
            <Row className='justify-content-center'>
                <Col md={11}>
                    
                    <p style={{ fontSize: "1.2em" }}>
                        В современном мире перевозка негабаритных грузов играет ключевую роль в логистических и производственных процессах. Компания Avtorent22 предлагает полный спектр услуг по перевозке негабаритных грузов в Барнауле и по всей России. Наша задача – обеспечить безопасную, эффективную и своевременную доставку вашего груза.
                    </p>
                    <h2 className="mb-5 my-4">Что такое негабаритный груз?</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        Негабаритные грузы – это грузы, превышающие стандартные размеры или вес, установленные для транспортировки по дорогам общего пользования. Это могут быть крупногабаритные машины, строительные материалы, оборудование для промышленных объектов и многое другое.
                    </p>
                    <h2 className="mb-5 my-4">Как мы перевозим грузы</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        Мы предлагаем полный спектр услуг, начиная от планирования маршрута и заканчивая доставкой груза до конечного пункта назначения. Наши услуги включают:
                        <li><b>Консультации по подготовке груза к перевозке</b> мы поможем вам подготовить все необходимые документы и убедимся, что ваш груз соответствует всем требованиям для перевозки.</li>
                        <li><b>Планирование маршрута: </b> наши специалисты разработают оптимальный маршрут, учитывая особенности вашего груза и требования дорожных условий.</li>
                        <li><b>Транспортировка:</b> мы используем специализированный транспорт, предназначенный для перевозки негабаритных грузов, что гарантирует безопасность и целостность вашего груза.</li>
                        <li><b>Сопровождение груза:  </b> при необходимости мы обеспечим сопровождение груза опытными логистами и обеспечим все необходимые сопутствующие услуги для обеспечения безопасности перевозки.</li>
                    </p>
                    <h2 className="mb-5 my-4">Наши преимущества</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        Выбирая Avtorent22 для перевозки негабаритных грузов, вы получаете:
                        <li><b>Профессиональный подход:</b> наши специалисты обладают глубокими знаниями в области логистики и перевозок, что позволяет нам предложить вам наилучшее решение для вашего запроса.</li>
                        <li><b>Индивидуальный подход к каждому клиенту:</b> мы понимаем, что каждый груз уникален, и предлагаем индивидуальные решения, наилучшим образом соответствующие вашим потребностям.</li>
                        <li><b>Соблюдение сроков:</b> мы понимаем значение времени в бизнесе и гарантируем своевременную доставку вашего груза.</li>
                        <li><b>Конкурентоспособные цены:</b> мы предлагаем высокое качество услуг по конкурентоспособным ценам, чтобы обеспечить наилучшее соотношение цены и качества.</li>
                    </p>

                    <h2 className="mb-5 my-4">Контакты</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        <li><b>Телефон:</b> +7 (3852) 99-33-88, +7 (905) 926-33-88</li>
                        <li><b>Email:</b> avtorent22@gmail.com</li>
                        <li><b>Адрес: </b> г. Барнаул, Георгия Исакова 116 б, к. </li>
                        <br />
                        Мы поможем вам с реализацией услуги и перевозки разнообразных грузов на разные расстояния. Обращайтесь к нам за перевозками грузов на грузовых авто в Барнауле!
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default OversizedCargoPage;
