import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Dropdown, Form, Modal, Row} from "react-bootstrap";
import {Context} from "../../index";
import {
    createTruck,
    deleteTruck,
    fetchTruckBrands,
    fetchTrucks,
    fetchTruckTypes,
    updateTruck
} from "../../http/truckAPI";
import {observer} from "mobx-react-lite";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const CreateTruck = observer( ({show, onHide}) => {
    const { truck } = useContext(Context)
    const [truck_name, setNameTruck] = useState('')
    const [truck_price, setPriceTruck] = useState(0)
    const [truck_year, setYearTruck] = useState(0)
    const [truck_img, setTruckImg] = useState([])
    const [truck_info, setTruckInfo] = useState([])
    const [truck_tariff, setTruckTariff] = useState([])
    const [truckName, setTruckName] = useState('');
    const [truckPrice, setTruckPrice] = useState('');
    const [truckYear, setTruckYear] = useState('');
    const [truckFile, setTruckFile] = useState([])
    const [truckInfoMap, setTruckInfoMap] = useState({})
    const [tariffInfoMap, setTariffInfoMap] = useState({})

    useEffect(()=>{
        fetchTruckTypes().then(data => truck.setTruckTypes(data))
        fetchTruckBrands().then(data => truck.setTruckBrands(data))
        fetchTrucks(null, null, 5000, 1).then(data => {
            truck.setTrucks(data.rows)
            truck.setTruckTotalCount(data.count)

            // Добавляем информацию о каждом автомобиле в carInfoMap
            const initialTruckInfoMap = {};
            data.rows.forEach((truck) => {
                initialTruckInfoMap[truck.id] = truck.truck_info.map(item => ({ ...item, number: item.number || uuidv4() })) || [];
            });

            setTruckInfoMap(initialTruckInfoMap);

            const initialTariffInfoMap = {};
            data.rows.forEach((truck) => {
                initialTariffInfoMap[truck.id] = truck.truck_tariff.map(item => ({ ...item, number: item.number || uuidv4() })) || [];
            });

            setTariffInfoMap(initialTariffInfoMap);
        })

    }, [])

    const addInfo = () => {
        setTruckInfo([...truck_info, {title: '', description: '', number: Date.now()}])
    }

    const removeInfo = (number) => {
        setTruckInfo(truck_info.filter(i => i.number !== number))
    }

    const changeInfo = (key, value, number) => {
        setTruckInfo(truck_info.map(i => i.number === number ? {...i, [key]:value} : i))
    }

    const addTariff = () => {
        setTruckTariff([...truck_tariff, {title: '', description: '', number: Date.now()}])
    }

    const removeTariff = (number) => {
        setTruckTariff(truck_tariff.filter(i => i.number !== number))
    }

    const changeTariff = (key, value, number) => {
        setTruckTariff(truck_tariff.map(i => i.number === number ? {...i, [key]:value} : i))
    }

    const addTruckInfo = (truckId) => {
        const newTruckInfoItem = { title: '', description: '', number: Date.now() };
        setTruckInfoMap((prevTruckInfoMap) => ({
            ...prevTruckInfoMap,
            [truckId]: [...(prevTruckInfoMap[truckId] || []), newTruckInfoItem],
        }));
    };

    const removeTruckInfo = (truckId, number) => {
        if (truckInfoMap[truckId]) {
            const updatedTruckInfo = truckInfoMap[truckId].filter((infoItem) => infoItem.number !== number);
            const updatedTruckInfoMap = { ...truckInfoMap, [truckId]: updatedTruckInfo };
            setTruckInfoMap(updatedTruckInfoMap);
        }
    };

    const changeTruckInfo = (truckId, key, value, number) => {
        const updatedTruckInfo = {
            ...truckInfoMap,
            [truckId]: truckInfoMap[truckId].map((infoItem) => {
                if (infoItem.number === number) {
                    return { ...infoItem, [key]: value };
                }
                return infoItem;
            }),
        };
        setTruckInfoMap(updatedTruckInfo);
    };

    const addTariffInfo = (truckId) => {
        const newTariffInfoItem = { title: '', description: '', number: Date.now() };
        setTariffInfoMap((prevTariffInfoMap) => ({
            ...prevTariffInfoMap,
            [truckId]: [...(prevTariffInfoMap[truckId] || []), newTariffInfoItem],
        }));
    };

    const removeTariffInfo = (truckId, number) => {
        if (tariffInfoMap[truckId]) {
            const updatedTariffInfo = tariffInfoMap[truckId].filter((infoItem) => infoItem.number !== number);
            const updatedTariffInfoMap = { ...tariffInfoMap, [truckId]: updatedTariffInfo };
            setTariffInfoMap(updatedTariffInfoMap);
        }
    };

    const changeTariffInfo = (truckId, key, value, number) => {
        const updatedTariffInfo = {
            ...tariffInfoMap,
            [truckId]: tariffInfoMap[truckId].map((infoItem) => {
                if (infoItem.number === number) {
                    return { ...infoItem, [key]: value };
                }
                return infoItem;
            }),
        };
        setTariffInfoMap(updatedTariffInfo);
    };


    const selectFile = (e) => {
        const files = e.target.files;
        const newFiles = [];

        // преобразовываем объект FileList в массив
        for (let i = 0; i < files.length; i++) {
            newFiles.push(files[i]);
        }

        setTruckImg([...truck_img, ...newFiles]);
    }

    const selectFileDevice = (e) => {
        const files = e.target.files;
        const newFiles = [];

        // преобразуем объект FileList в массив
        for (let i = 0; i < files.length; i++) {
            newFiles.push(files[i]);
        }

        setTruckFile(newFiles);
    }

    const resetForm = () => {
        setNameTruck('');
        setPriceTruck(0);
        setYearTruck(0);
        setTruckImg([]);
        setTruckInfo([]);
        setTruckTariff([]);
    };


    const addDevice = () => {
        const formData = new FormData()
        formData.append('name', truck_name)
        formData.append('price', `${truck_price}`)
        formData.append('year', `${truck_year}`)
        for (let i = 0; i < truck_img.length; i++) {
            formData.append('images', truck_img[i])
        }
        formData.append('truckBrandId', truck.selectedTruckBrand.id)
        formData.append('truckTypeId', truck.selectedTruckType.id)
        formData.append('truck_info', JSON.stringify(truck_info))
        formData.append('truck_tariff', JSON.stringify(truck_tariff))
        createTruck(formData).then((data) =>
        {
            alert("Добавление грузового прошло успешно"); // Вывести сообщение
            window.location.reload(); // Перезагрузка страницы
        });
    }

    const handleDelete = (id) => {
        deleteTruck(id).then((data) =>
        {
            alert("Удаление грузового прошло успешно"); // Вывести сообщение
            window.location.reload(); // Перезагрузка страницы
        })
    };

    const updateDev = (id, truckId) => {
        const formData = new FormData()
        if (truck_name !== truckName) {
            formData.append('name', truckName);
        }
        if (truck_price !== Number(truckPrice)) {
            formData.append('price', `${truckPrice}`);
        }
        if (truck_year !== Number(truckYear)) {
            formData.append('year', `${truckYear}`);
        }

        if (truckFile.length > 0) {
            // Если есть новые файлы, добавляем их в formData
            for (let i = 0; i < truckFile.length; i++) {
                formData.append('images', truckFile[i]);
            }
        } else {
            // Если carFile пуст, отправляем старые изображения
            const oldImages = truck_img.map(image => new File([], image));
            for (let i = 0; i < oldImages.length; i++) {
                formData.append('images', oldImages[i]);
            }
        }

        if (truckInfoMap) {
            const truckInfo = truckInfoMap[id];
            if (truckInfo) {
                const formattedData = truckInfo.map((infoItem) => ({
                    title: infoItem.title,
                    description: infoItem.description,
                }));
                formData.append('truck_info', JSON.stringify(formattedData));
            }
        }
        if (tariffInfoMap) {
            const tariffInfo = tariffInfoMap[id];
            if (tariffInfo) {
                const formattedData = tariffInfo.map((infoItem) => ({
                    title: infoItem.title,
                    description: infoItem.description,
                }));
                formData.append('truck_tariff', JSON.stringify(formattedData));
            }
        }
        // Отправляем запрос на обновление
        updateTruck(id, formData).then((data) =>
        {
            alert("Обновление грузового прошло успешно"); // Вывести сообщение
            window.location.reload(); // Перезагрузка страницы
        });
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
            }}
            size="xl"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить продукт
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="justify-content-center">
                        <Col className='col-md-8 '>
                <Form>
                    <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{truck.selectedTruckType.name || "Выберите тип"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {truck.truck_types.map(truck_type =>
                                <Dropdown.Item
                                    onClick={() => truck.setTruckSelectedType(truck_type)}
                                    key={truck_type.id}
                                >
                                    {truck_type.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{truck.selectedTruckBrand.name || "Выберите бренд"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {truck.truck_brands.map(truck_brand =>
                                <Dropdown.Item
                                    onClick={() => truck.setTruckSelectedBrand(truck_brand)}
                                    key={truck_brand.id}
                                >
                                    {truck_brand.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <p>Название:</p>
                    <Form.Control
                        value={truck_name}
                        onChange={e => setNameTruck(e.target.value)}
                        className="mt-3"
                        placeholder="Введите название продукта"
                    />
                    <br/>
                    <p>Стоимость:</p>
                    <Form.Control
                        value={truck_price}
                        onChange={e => setPriceTruck(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Введите стоимость устройства"
                        type="number"
                    />
                    <br/>
                    <p>Год выпуска:</p>
                    <Form.Control
                        value={truck_year}
                        onChange={e => setYearTruck(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Введите год выпуска"
                        type="number"/>
                    <br/>
                    <Form.Control
                        className="mt-3"
                        type="file"
                        onChange={selectFile}
                        multiple
                    />
                    <hr/>
                    <Button
                        variant="outline-dark"
                        onClick={addInfo}
                    >
                        Добавить характеристку
                    </Button>
                    {
                        truck_info.map(i =>
                            <Row className="mt-4" key={i.number}>
                                <Col md={4} className="p-2">
                                    <Form.Control
                                        value={i.title}
                                        onChange={(e) => changeInfo('title', e.target.value, i.number)}
                                        placeholder="Введите название свойства"
                                    />
                                </Col>
                                <Col md={4} className="p-2">
                                    <Form.Control
                                        value={i.description}
                                        onChange={(e) => changeInfo('description', e.target.value, i.number)}
                                        placeholder="Введите описание свойства"
                                    />
                                </Col>
                                <Col md={4}className="p-2">
                                    <Button
                                        onClick={() => removeInfo(i.number)}
                                        variant={"outline-danger"}>
                                        Удалить
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }
                    <hr/>
                    <Button
                        variant="outline-dark"
                        onClick={addTariff}
                    >
                        Добавить тариф
                    </Button>
                    {
                        truck_tariff.map(i =>
                            <Row className="mt-4" key={i.number}>
                                <Col md={4} className="p-2">
                                    <Form.Control
                                        value={i.title}
                                        onChange={(e) => changeTariff('title', e.target.value, i.number)}
                                        placeholder="Введите название свойства"
                                    />
                                </Col>
                                <Col md={4} className="p-2">
                                    <Form.Control
                                        value={i.description}
                                        onChange={(e) => changeTariff('description', e.target.value, i.number)}
                                        placeholder="Введите описание свойства"
                                    />
                                </Col>
                                <Col md={4}className="p-2">
                                    <Button
                                        onClick={() => removeTariff(i.number)}
                                        variant={"outline-danger"}>
                                        Удалить
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }
                </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-success"} disabled={!truck_img} onClick={addDevice}>Добавить</Button>
            </Modal.Footer>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактировать продукт
                </Modal.Title>
            </Modal.Header>
            <Container>
                <Row className='align-items-start justify-content-center'>
                {truck.trucks.map(truck =>
                    <Col className='col-xl-6 mt-3' key={truck.id}>
                        <Card className='align-items-center p-3' key={truck.id} >
                                <Card.Body className={'w-100'}>
                                    <Card.Title>
                                        <Form>
                                            <h4>Основная информация:</h4>
                                            <Form.Group className="mb-3">
                                                <br/>
                                                <p>Название:</p>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    placeholder="Изменить название"
                                                    defaultValue={truck.name}
                                                    onChange={(event) => {
                                                        setTruckName(event.target.value);
                                                    }}
                                                />
                                                <br/>
                                                <p>Фото:</p>
                                                <Form.Control
                                                    className="mt-3"
                                                    type="file"
                                                    onChange={selectFileDevice}
                                                    multiple
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <p>Цена:</p>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    placeholder="Изменить цену"
                                                    defaultValue={truck.price}
                                                    onChange={(event) => {
                                                        setTruckPrice(event.target.value);
                                                    }}
                                                />
                                                <br/>
                                                <p>Год:</p>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    placeholder="Изменить год"
                                                    defaultValue={truck.year}
                                                    onChange={(event) => {
                                                        setTruckYear(event.target.value);
                                                    }}
                                                />
                                                <br/>
                                                <h4>Характеристики:</h4>
                                                {truckInfoMap[truck.id] && truckInfoMap[truck.id].map((item, index) => (
                                                    <Row className="mt-4" key={item.number ?? index}>
                                                        <Col md={5} className="p-2">
                                                            <Form.Control
                                                                defaultValue={item.title}
                                                                onChange={(e) => changeTruckInfo(truck.id, 'title', e.target.value, item.number)}
                                                                placeholder="Введите название свойства"
                                                            />
                                                        </Col>
                                                        <Col md={5} className="p-2">
                                                            <Form.Control
                                                                defaultValue={item.description}
                                                                onChange={(e) => changeTruckInfo(truck.id, 'description', e.target.value, item.number)}
                                                                placeholder="Введите описание свойства"
                                                            />
                                                        </Col>
                                                        <Col md={2} className="p-2">
                                                            <Button className={"w-100"} onClick={() => removeTruckInfo(truck.id, item.number)} variant="outline-danger">
                                                                <FontAwesomeIcon icon={faXmark} />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Button variant="outline-dark" className={"mt-4"} onClick={() => addTruckInfo(truck.id)}>
                                                    Добавить характеристики
                                                </Button>
                                                <hr/>
                                                <h4>Условия:</h4>
                                                {tariffInfoMap[truck.id] && tariffInfoMap[truck.id].map((item, index) => (
                                                    <Row className="mt-4" key={item.number ?? index}>
                                                        <Col md={5} className="p-2">
                                                            <Form.Control
                                                                defaultValue={item.title}
                                                                onChange={(e) => changeTariffInfo(truck.id, 'title', e.target.value, item.number)}
                                                                placeholder="Введите название свойства"
                                                            />
                                                        </Col>
                                                        <Col md={5} className="p-2">
                                                            <Form.Control
                                                                defaultValue={item.description}
                                                                onChange={(e) => changeTariffInfo(truck.id, 'description', e.target.value, item.number)}
                                                                placeholder="Введите описание свойства"
                                                            />
                                                        </Col>
                                                        <Col md={2} className="p-2">
                                                            <Button className={"w-100"} onClick={() => removeTariffInfo(truck.id, item.number)} variant="outline-danger">
                                                                <FontAwesomeIcon icon={faXmark} />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Button variant="outline-dark" className={"mt-4"} onClick={() => addTariffInfo(truck.id)}>
                                                    Добавить условия
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Card.Title>
                                    <Modal.Footer>
                                        <Button variant='outline-success' onClick={() =>
                                            updateDev( truck.id, {
                                                name:  truckName,
                                                price:  truckPrice,
                                                year:  truckYear,
                                                images: truckFile,
                                                truck_info: truckInfoMap,
                                                truck_tariff: tariffInfoMap,
                                            })
                                        }
                                        >
                                            Принять изменения
                                        </Button>
                                        <Button variant={"outline-danger"} onClick={() => handleDelete(truck.id)}>Удалить товар</Button>
                                    </Modal.Footer>
                                </Card.Body>
                        </Card>
                    </Col>
                )}
                </Row>
            </Container>
        </Modal>
    );
});


export default CreateTruck;