import React, { useContext, useEffect } from "react";
import CarList from "../components/CarList";
import { fetchBrands, fetchCars, fetchTypes } from "../http/carAPI";
import { Context } from "../index";
import { Card, Col, Container, Image } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import car2 from "../assets/блок1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCheck, faHandshake, faHeadset, faShieldAlt, faTruck } from "@fortawesome/free-solid-svg-icons";
import car1 from "../assets/Коллаж3.png";



const CarsShop = () => {
    const { car } = useContext(Context)
    useEffect(() => {
        fetchTypes().then(data => car.setTypes(data))
        fetchBrands().then(data => car.setBrands(data))
        fetchCars(car.selectedType.id, car.selectedBrand.id, car.page, car.img, 24).then(data => {
            car.setCars(data.rows)
            car.setTotalCount(data.count)
            car.setImg(data.img);
        })
    }, [ car.page, car.selectedType, car.selectedBrand, car.img ])
    useEffect(() => {
        const newTitle = "Прокат легковых авто в Барнауле – AVTORENT22";
        const newDescription = "У нас вы сможете оформить прокат легковых авто в Барнауле по выгодной цене";

        // Устанавливаем новый заголовок страницы, если он отличается от текущего
        if (document.title !== newTitle) {
            document.title = newTitle;
        }

        // Находим мета-тег с именем "description" и устанавливаем его содержимое
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", newDescription);
        }
    }, []);
    return (
        <Container fluid className='razd'>
            <Row className="justify-content-center align-self-center preim3">
                <h1 style={{ color: "white" }}>Каталог легковых автомобилей</h1>
            </Row>
            <div className="my-4">
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={12} className='cat'>
                            <CarList />
                        </Col>
                        <hr />
                        <Col md={6}>
                            <h2 className="mb-5 my-4">Прокат авто в Барнауле</h2>
                            <p style={{ fontSize: "1.2em" }}>

                                Добро пожаловать в Avtorent22, вашего надежного партнера по прокату легковых автомобилей в Барнауле! Если вы ищете удобный и надежный способ передвижения по городу или за его пределами, наши услуги — это именно то, что вам нужно. Мы предлагаем широкий выбор автомобилей на любой вкус и бюджет, чтобы вы могли насладиться своей поездкой с максимальным комфортом
                                <br /><br />
                                У нас вы найдете обширный парк легковых автомобилей, включая как экономичные варианты для городских поездок, так и премиальные модели для особых случаев. Все наши автомобили проходят регулярное техническое обслуживание и тщательную подготовку, чтобы обеспечить вашу безопасность и комфорт на дороге.
                            </p>


                        </Col>
                        <Col md={5} className='align-self-center'>
                            <Image src={car2} width={'100%'} style={{ borderRadius: "10px" }} />
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md={11}>
                            <h2 className="mb-5 my-4">Преимущества работы с нами</h2>
                            <p style={{ fontSize: "1.2em" }}>

                                <li><b>Разнообразие автомобилей:</b> от компактных городских авто до роскошных моделей для особых моментов.</li>
                                <li><b>Гибкость:</b> краткосрочный и долгосрочный прокат, возможность возврата в удобное для вас время и место.</li>
                                <li><b>Прозрачные условия аренды:</b> без скрытых платежей и комиссий.</li>
                                <li><b>Поддержка 24/7: </b>  наша команда всегда готова помочь вам с любыми вопросами или проблемами.</li>
                                <br /><br />
                                Мы гарантируем высокое качество наших услуг и полную прозрачность сделки. В случае возникновения любых непредвиденных ситуаций на дороге, наша команда оперативно предоставит всю необходимую поддержку и, при необходимости, заменит автомобиль.
                            </p>
                            <h2 className="mb-5 my-4">Как арендовать</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                Арендовать автомобиль в Avtorent22 просто и удобно. Вам нужно только выбрать желаемую модель автомобиля на нашем сайте, указать даты проката и заполнить короткую форму бронирования. Мы свяжемся с вами для подтверждения брони и обсудим все детали.
                                <br /><br />
                                Необходимые документы:
                                <br /><br />
                                <li>Паспорт (Вам от 21 года);</li>
                                <li>Водительское удостоверение (срок вождения – от 2 лет);</li>

                            </p>
                            <h2 className="mb-5 my-4">Наши автомобили</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                Мы гордимся нашим автопарком, который включает в себя только надежные и комфортные автомобили от ведущих мировых производителей (Hyundai, Kia, Haval и другие). Каждый автомобиль оборудован всем необходимым для вашего удобства: кондиционером, мультимедийной системой, навигацией и системой безопасности. Выбирая Авторентt22, вы выбираете качество и надежность.
                            </p>
                            <h2 className="mb-5 my-4">Зачем арендуют автомобили</h2>
                            <p style={{ fontSize: "1.2em" }}>

                                <ul>
                                    <b>Туристические поездки:</b> путешественники часто берут автомобили для исследования новых мест, поскольку это дает свободу передвижения без необходимости зависеть от общественного транспорта.
                                </ul>
                                <ul>

                                    <b>Деловые поездки:</b> Бизнес-профессионалы берут транспорт для поездок на встречи, конференции или другие деловые мероприятия, особенно в незнакомых городах.
                                </ul>
                                <ul>
                                    <b>Специальные события:</b> свадьбы, выпускные, юбилеи, и другие важные мероприятия часто требуют аренды особых или роскошных автомобилей для транспортировки гостей или для особого впечатления.

                                </ul>
                                <ul>
                                    <b>Замена своего автомобиля:</b>  в случае поломки или находящегося на обслуживании личного транспорта, услуга предоставляет временное транспортное средство, чтобы оставаться мобильным.

                                </ul>
                                <ul>
                                    <b>Тест-драйв: </b>  прежде чем принять решение о покупке нового авто, некоторые предпочитают арендовать желаемую модель на несколько дней, чтобы лучше понять, подходит ли она их потребностям.

                                </ul>



                            </p>
                            <h2 className="mb-5 my-4">Контактная информация</h2>
                            <p style={{ fontSize: "1.2em" }}>
                                Готовы к новым приключениям? Свяжитесь с нами сегодня! Мы расположены в центре Барнаула и всегда рады видеть вас в нашем офисе. Для бронирования автомобиля или получения консультации позвоните нам или отправьте заявку через сайт.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Row className="justify-content-center preim">
                <h2 className="text-center mb-5 prem">Наши премущества</h2>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faHeadset} size="3x" className="mb-3 mt-3 preimicon" />
                        <Card.Body>
                            <Card.Title>Мы всегда на связи</Card.Title>
                            <Card.Text>
                                Наша служба поддержки работает круглосуточно, чтобы ответить на все ваши вопросы и решить проблемы.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faCar} size="3x" className="mb-3 mt-3 preimicon" />
                        <Card.Body>
                            <Card.Title>Большой ассортимент автомобилей</Card.Title>
                            <Card.Text>
                                У нас вы можете выбрать автомобиль из широкого ассортимента моделей и марок, чтобы удовлетворить все ваши потребности.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faCheck} size="3x" className="mb-3 mt-3 preimicon" />
                        <Card.Body>
                            <Card.Title>Качество полностью соответствует цене услуги</Card.Title>
                            <Card.Text>
                                Мы предлагаем высокое качество автомобилей и услуг, которое соответствует цене, которую вы платите.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faTruck} size="3x" className="mb-3 mt-3 preimicon" />
                        <Card.Body>
                            <Card.Title>Быстрая и качественная доставка авто</Card.Title>
                            <Card.Text>
                                Мы гарантируем быструю и качественную доставку автомобиля по месту назначения, чтобы вы могли наслаждаться своей поездкой без задержек.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faShieldAlt} size="3x" className="mb-3 mt-3 preimicon" />
                        <Card.Body>
                            <Card.Title>Полная гарантия на услугу</Card.Title>
                            <Card.Text>
                                Мы предоставляем полную гарантию на все наши услуги, чтобы вы могли быть уверены в качестве наших автомобилей и обслуживания.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="4" className="mb-4">
                    <Card className="info-card">
                        <FontAwesomeIcon icon={faHandshake} size="3x" className="mb-3 mt-3 preimicon" />
                        <Card.Body>
                            <Card.Title>Мягкие условия на аренду</Card.Title>
                            <Card.Text>
                                Мы готовы предоставить вам мягкие условия на аренду автомобиля, чтобы вы могли выбрать наиболее удобный и выгодный вариант для себя.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className=" text-center rental-conditions justify-content-center prokat">
                <h2 className="text-center prem">Условия проката</h2>
                <Col md={3} className='mt-4 '>
                    <Card className="info-card info-card1">
                        <Card.Body>
                            <Card.Title><h4>Условия аренды автомобиля:</h4></Card.Title>
                            <Card.Text>
                                <ul>
                                    <li><b>Возраст водителя:</b> от 21 года</li>
                                    <li><b>Стаж вождения:</b> от 2-х лет</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3} className='mt-4 '>
                    <Card className="info-card info-card1">
                        <Card.Body>
                            <Card.Title><h4>Документы для аренды:</h4></Card.Title>
                            <Card.Text>
                                <ul>
                                    <li>Паспорт гражданина РФ</li>
                                    <li>Водительское удостоверение</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3} className='mt-4 align-self-center'>
                    <Card className="info-card info-card1">
                        <Card.Body>
                            <Card.Title>
                                <h4>
                                    Стоимость доставки:
                                </h4>
                            </Card.Title>
                            <Card.Text>
                                <ul>
                                    <li>В черте Барнаула: <b>500-1000</b> руб.</li>
                                    <li>В аэропорт Барнаула:</li>
                                    <li><b>1000</b> руб. (c 9:00 до 18:00)</li>
                                    <li><b>1500</b> руб. (c 18:00 до 9:00)</li>
                                    <li> В аэропорт Новосибирска, Горно-Алтайска: <b>8000-8500</b> руб.</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className=" text-center rental-conditions rental-conditions1 justify-content-center prokat1">
                <div className="my-5">
                    <Container>

                        <Row className='justify-content-between'>
                            <Col md={5} className='align-self-center'>
                                <Image src={car1} width={'100%'} style={{ borderRadius: "10px" }} />
                            </Col>
                            <Col md={6}>
                                <h1 className="mb-5 text-bg-danger p-2" align={'left'}>Причины для аренды</h1>
                                <p align={'left'} className='text-white p-2' style={{ fontSize: "1.2em" }}>
                                    Многие интересуются: «Куда чаще всего заказывают автомобили?» Это могут быть такие мероприятия, как свадьба, День Рождения или юбилей. Также для каких-то массовых мероприятий, куда необходимо доставить людей. Можно так же заказать автомобиль для прогулки с любимой девушкой. <br /><br />Вариантов множество, главное — <b>ваше желание</b> и наша возможность предоставить вам транспорт. У нас представлен большой автопарк, из моделей которого вы можете выбрать подходящий для себя по форме, размеру и качеству. Любая подходящая марка машины будет предложена вам в течение часа.
                                    <br /><br />
                                    Поверьте, что цена на прокат автомобиля не оставит вас равнодушными. Выбирайте подходящее авто и делайте заказ прямо сейчас.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Row>
        </Container>
    );
}

export default CarsShop