import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "react-phone-input-2/lib/style.css";
import '../styles/carpage.css'

const ExcavatorPage = () => {

    return (
        <Container>
            <Row className='justify-content-center'>
                <Col md={11}>

                    <p style={{ fontSize: "1.2em" }}>
                        Добро пожаловать в Avtorent22, вашего надежного партнера в аренде экскаваторов-погрузчиков в Барнауле! Мы предлагаем широкий спектр услуг экскаватора-погрузчика для выполнения различных видов земельных и строительных работ. Наши машины идут вместе с опытными операторами, чтобы обеспечить максимальную эффективность и безопасность на вашем объекте.
                    </p>
                    <h2 className="mb-5 my-4">Наши услуги</h2>

                    <p style={{ fontSize: "1.2em" }}>
                        <li><b>Выкопка котлованов и траншей:</b> идеально подходит для подготовки оснований под строительство, укладку коммуникаций и многое другое.</li>
                        <li><b>Планировка территории:</b> с помощью экскаватора-погрузчика мы быстро и аккуратно выровняем участок под строительство или благоустройство.</li>
                        <li><b>Разгрузочно-погрузочные работы:</b> эффективно перемещаем строительные материалы, облегчая логистику на строительной площадке.</li>
                        <li><b>Демонтажные работы:</b> безопасный и контролируемый демонтаж зданий, сооружений, а также удаление строительного мусора.</li>
                        <li><b>Зимние услуги:</b> очистка территории от снега и льда, обеспечивая безопасность и доступность вашего объекта круглый год.</li>
                    </p>
                    <h2 className="mb-5 my-4">Почему выбирают нас?</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        <li><b>Профессионализм:</b> наши операторы экскаваторов-погрузчиков обладают глубокими знаниями и многолетним опытом работы.</li>
                        <li><b>Современная техника:</b> мы используем только надежные и высокопроизводительные машины, регулярно проходящие техническое обслуживание.</li>
                        <li><b>Гибкость:</b>готовы предложить индивидуальный подход и оптимизировать предложение под конкретные задачи и бюджет клиента.</li>
                        <li><b>Безопасность: </b> соблюдение всех норм и правил безопасности на объекте — наш приоритет.</li>
                    </p>
                    <h2 className="mb-5 my-4">Как заказать?</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        Чтобы заказать услуги экскаватора-погрузчика в Барнауле, просто свяжитесь с нами по указанным на сайте контактам. Наши специалисты ответят на все вопросы, помогут подобрать оптимальный вариант услуг и оперативно оформят заказ.
                    </p>

                    <h2 className="mb-5 my-4">Контакты</h2>
                    <p style={{ fontSize: "1.2em" }}>
                        По всем вопросам аренды экскаваторов-погрузчиков в Барнауле обращайтесь в Avtorent22. Мы всегда рады предложить вам лучшие условия сотрудничества и помочь реализовать ваш проект с максимальной эффективностью!
                        <li><b>Телефон:</b> +7 (3852) 99-33-88, +7 (905) 926-33-88</li>
                        <li><b>Email:</b> avtorent22@gmail.com</li>
                        <li><b>Адрес: </b> г. Барнаул, Георгия Исакова 116 б, к. </li>
                        

                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default ExcavatorPage;
