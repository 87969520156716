import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Dropdown, Form, Modal, Row} from "react-bootstrap";
import {Context} from "../../index";
import {
    createCar,
    deleteCar,
    fetchBrands,
    fetchCars, fetchServices,
    fetchTypes,
    updateCar
} from "../../http/carAPI";
import {observer} from "mobx-react-lite";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const CreateCar = observer( ({show, onHide}) => {
    const {car} = useContext(Context)
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [year, setYear] = useState(0)
    const [deposit, setDeposit] = useState(0)
    const [img, setImg] = useState([])
    const [price_per_day_1_2_days, setPrice_per_day_1_2_days] = useState(0)
    const [price_per_day_3_7_days, setPrice_per_day_3_7_days] = useState(0)
    const [price_per_day_8_20_days, setPrice_per_day_8_20_days] = useState(0)
    const [price_per_day_21_days_and_more, setPrice_per_day_21_days_and_more] = useState(0)
    const [info, setInfo] = useState([])
    const [carName, setCarName] = useState('');
    const [carPrice, setCarPrice] = useState('');
    const [carYear, setCarYear] = useState('');
    const [carDeposit, setCarDeposit] = useState('');
    const [carPrice_per_day_1_2_days, setCarPrice_per_day_1_2_days] = useState('');
    const [carPrice_per_day_3_7_days, setCarPrice_per_day_3_7_days] = useState('');
    const [carPrice_per_day_8_20_days, setCarPrice_per_day_8_20_days] = useState('');
    const [carPrice_per_day_21_days_and_more, setCarPrice_per_day_21_days_and_more] = useState('');
    const [carFile, setCarFile] = useState([]);
    const [carInfoMap, setCarInfoMap] = useState({});


    useEffect(()=>{
        fetchTypes().then(data => car.setTypes(data))
        fetchServices().then(data => car.setServices(data))
        fetchBrands().then(data => car.setBrands(data))
        fetchCars(null, null, 1, 5000).then(data => {
            car.setCars(data.rows);
            car.setTotalCount(data.count);

            // Добавляем информацию о каждом автомобиле в carInfoMap
            const initialCarInfoMap = {};
            data.rows.forEach((car) => {
                initialCarInfoMap[car.id] = car.info.map(item => ({ ...item, number: item.number || uuidv4() })) || [];
            });

            // Сортируем массив info внутри каждого carId
            Object.keys(initialCarInfoMap).forEach((carId) => {
                initialCarInfoMap[carId].sort((a, b) => a.id - b.id);
            });

            setCarInfoMap(initialCarInfoMap);

            console.log("Sorted carInfoMap:", initialCarInfoMap);
        })
    }, [])


    const addInfo = () => {
        setInfo([...info, {title: '', description: '', number: Date.now()}])
    }

    const removeInfo = (number) => {
        setInfo(info.filter(i => i.number !== number))
    }

    const changeInfo = (key, value, number) => {
        setInfo(info.map(i => i.number === number ? {...i, [key]:value} : i))
    }
    const addCarInfo = (carId) => {
        const newCarInfoItem = { title: '', description: '', number: Date.now() };
        setCarInfoMap((prevCarInfoMap) => ({
            ...prevCarInfoMap,
            [carId]: [...(prevCarInfoMap[carId] || []), newCarInfoItem],
        }));
        console.log(setCarInfoMap)
    };

    const removeCarInfo = (carId, number) => {
        if (carInfoMap[carId]) {
            const updatedCarInfo = carInfoMap[carId].filter((infoItem) => infoItem.number !== number);
            const updatedCarInfoMap = { ...carInfoMap, [carId]: updatedCarInfo };
            setCarInfoMap(updatedCarInfoMap);
        }
    };

    const changeCarInfo = (carId, key, value, number) => {
        const updatedCarInfo = {
            ...carInfoMap,
            [carId]: carInfoMap[carId].map((infoItem) => {
                if (infoItem.number === number) {
                    return { ...infoItem, [key]: value };
                }
                return infoItem;
            }),
        };
        setCarInfoMap(updatedCarInfo);
    };

    const selectFile = (e) => {
        const files = e.target.files;
        const newFiles = [];

        // преобразовываем объект FileList в массив
        for (let i = 0; i < files.length; i++) {
            newFiles.push(files[i]);
        }

        setImg([...img, ...newFiles]);
    }

    const selectFileDevice = (e) => {
            const files = e.target.files;
            const newFiles = [];

            // преобразуем объект FileList в массив
            for (let i = 0; i < files.length; i++) {
                newFiles.push(files[i]);
            }

        setCarFile(newFiles);
    }
    const addDevice = () => {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('price', `${price}`)
        formData.append('year', `${year}`)
        formData.append('deposit', `${deposit}`)
        formData.append('price_per_day_1_2_days', `${price_per_day_1_2_days}`)
        formData.append('price_per_day_3_7_days', `${price_per_day_3_7_days}`)
        formData.append('price_per_day_8_20_days', `${price_per_day_8_20_days}`)
        formData.append('price_per_day_21_days_and_more', `${price_per_day_21_days_and_more}`)
        for (let i = 0; i < img.length; i++) {
            formData.append('images', img[i])
        }
        formData.append('brandId', car.selectedBrand.id)
        formData.append('typeId', car.selectedType.id)
        formData.append('info', JSON.stringify(info))
        createCar(formData).then((data) =>
        {
            alert("Добавление авто прошло успешно"); // Вывести сообщение
            window.location.reload(); // Перезагрузка страницы
        });

    }
    const handleDelete = (id) => {
        deleteCar(id).then((data) =>
        {
            alert("Удаление авто прошло успешно"); // Вывести сообщение
            window.location.reload(); // Перезагрузка страницы
        })
    };

    const updateDev = (id, carId) => {
        const formData = new FormData();

        // Добавляем только измененные параметры
        if (name !== carName) {
            formData.append('name', carName);
        }

        if (price !== Number(carPrice)) {
            formData.append('price', `${carPrice}`);
        }

        if (year !== Number(carYear)) {
            formData.append('year', `${carYear}`);
        }

        if (deposit !== Number(carDeposit)) {
            formData.append('deposit', `${carDeposit}`);
        }

        if (price_per_day_1_2_days !== Number(carPrice_per_day_1_2_days)) {
            formData.append('price_per_day_1_2_days', `${carPrice_per_day_1_2_days}`);
        }

        if (price_per_day_3_7_days !== Number(carPrice_per_day_3_7_days)) {
            formData.append('price_per_day_3_7_days', `${carPrice_per_day_3_7_days}`);
        }

        if (price_per_day_8_20_days !== Number(carPrice_per_day_8_20_days)) {
            formData.append('price_per_day_8_20_days', `${carPrice_per_day_8_20_days}`);
        }

        if (price_per_day_21_days_and_more !== Number(carPrice_per_day_21_days_and_more)) {
            formData.append('price_per_day_21_days_and_more', `${carPrice_per_day_21_days_and_more}`);
        }

        if (carFile.length > 0) {
            // Если есть новые файлы, добавляем их в formData
            for (let i = 0; i < carFile.length; i++) {
                formData.append('images', carFile[i]);
            }
        } else {
            // Если carFile пуст, отправляем старые изображения
            const oldImages = img.map(image => new File([], image));
            for (let i = 0; i < oldImages.length; i++) {
                formData.append('images', oldImages[i]);
            }
        }

        if (carInfoMap) {
            const carInfo = carInfoMap[id];
            if (carInfo) {
                const formattedData = carInfo.map((infoItem) => ({
                    title: infoItem.title,
                    description: infoItem.description,
                }));
                formData.append('info', JSON.stringify(formattedData));
            }
        }
        console.log(formData)

        updateCar(id, formData).then((data) =>
        {
            alert("Обновление авто прошло успешно"); // Вывести сообщение
            window.location.reload(); // Перезагрузка страницы
        });
    };


    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
            }}
            size="xl"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить продукт
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="justify-content-center">
                        <Col className='col-md-8 '>
                <Form>
                    <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{car.selectedType.name || "Выберите тип"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {car.types.map(type =>
                                <Dropdown.Item
                                    onClick={() => car.setSelectedType(type)}
                                    key={type.id}
                                >
                                    {type.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{car.selectedBrand.name || "Выберите бренд"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {car.brands.map(brand =>
                                <Dropdown.Item
                                    onClick={() => car.setSelectedBrand(brand)}
                                    key={brand.id}
                                >
                                    {brand.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <p>Название:</p>
                    <Form.Control
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="mt-3"
                        placeholder="Введите название продукта"
                    />
                    <br/>
                    <p>Стоимость:</p>
                    <Form.Control
                        value={price}
                        onChange={e => setPrice(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Введите стоимость устройства"
                        type="number"
                    />
                    <br/>
                    <p>Год выпуска:</p>
                    <Form.Control
                        value={year}
                        onChange={e => setYear(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Введите год выпуска"
                        type="number"
                    />
                    <br/>
                    <p>Залог:</p>
                    <Form.Control
                        value={deposit}
                        onChange={e => setDeposit(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Залог"
                        type="number"
                    />
                    <br/>
                    <h4>Тарифы:</h4>
                    <br/>
                    <p>1-2 суток</p>
                    <Form.Control
                        value={price_per_day_1_2_days}
                        onChange={e => setPrice_per_day_1_2_days(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Тариф"
                        type="number"
                    />
                    <br/>
                    <p>3-7 суток</p>
                    <Form.Control
                        value={price_per_day_3_7_days}
                        onChange={e => setPrice_per_day_3_7_days(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Тариф"
                        type="number"
                    />
                    <br/>
                    <p>8-20 суток</p>
                    <Form.Control
                        value={price_per_day_8_20_days}
                        onChange={e => setPrice_per_day_8_20_days(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Тариф"
                        type="number"
                    />
                    <br/>
                    <p>21 и больше</p>
                    <Form.Control
                        value={price_per_day_21_days_and_more}
                        onChange={e => setPrice_per_day_21_days_and_more(Number(e.target.value))}
                        className="mt-3"
                        placeholder="Тариф"
                        type="number"
                    />
                    <br/>
                    <p>Выбрать фото:</p>
                    <Form.Control
                        className="mt-3"
                        type="file"
                        onChange={selectFile}
                        multiple
                    />
                    <hr/>
                    <Button
                        variant="outline-dark"
                        onClick={addInfo}
                    >
                        Добавить характеристики
                    </Button>
                    {
                        info.map(i =>
                            <Row className="mt-4" key={i.number}>
                                <Col md={4} className="p-2">
                                    <Form.Control
                                        value={i.title}
                                        onChange={(e) => changeInfo('title', e.target.value, i.number)}
                                        placeholder="Введите название свойства"
                                    />
                                </Col>
                                <Col md={4} className="p-2">
                                    <Form.Control
                                        value={i.description}
                                        onChange={(e) => changeInfo('description', e.target.value, i.number)}
                                        placeholder="Введите описание свойства"
                                    />
                                </Col>
                                <Col md={4}className="p-2">
                                    <Button
                                        onClick={() => removeInfo(i.number)}
                                        variant={"outline-danger"}>
                                        Удалить
                                    </Button>
                                </Col>
                            </Row>
                        )
                    }
                </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-success"} disabled={!img} onClick={addDevice}>Добавить</Button>
            </Modal.Footer>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактировать продукт
                </Modal.Title>
            </Modal.Header>
            <Container>
                <Row className='align-items-start justify-content-center'>
                {car.cars.map(car =>
                        <Col className='col-xl-6 mt-3' key={car.id}>
                            <Card className='align-items-center p-3' key={car.id} >
                                    <Card.Body className={'w-100'}>
                                        <Card.Title>
                                            <Form>
                                                <h4>Основная информация:</h4>
                                                <br/>
                                                <Form.Group className="mb-3">
                                                    <p>Название:</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="Изменить название"
                                                        defaultValue={car.name}
                                                        onChange={(event) => {
                                                            setCarName(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <p>Фото:</p>
                                                    <Form.Control
                                                        className="mt-3"
                                                        type="file"
                                                        onChange={selectFileDevice}
                                                        multiple
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <p>Цена:</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="Изменить цену"
                                                        defaultValue={car.price}
                                                        onChange={(event) => {
                                                            setCarPrice(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <p>Залог:</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="Изменить залог"
                                                        defaultValue={car.deposit}
                                                        onChange={(event) => {
                                                            setCarDeposit(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <p>Год выпуска:</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="Изменить год"
                                                        defaultValue={car.year}
                                                        onChange={(event) => {
                                                            setCarYear(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <h4>Тарифы:</h4>
                                                    <br/>
                                                    <p>1-2 суток</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="1-2 суток"
                                                        defaultValue={car.price_per_day_1_2_days}
                                                        onChange={(event) => {
                                                            setCarPrice_per_day_1_2_days(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <p>3-7 суток</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="3-7 суток"
                                                        defaultValue={car.price_per_day_3_7_days}
                                                        onChange={(event) => {
                                                            setCarPrice_per_day_3_7_days(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <p>8-20 суток</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="8-20 суток"
                                                        defaultValue={car.price_per_day_8_20_days}
                                                        onChange={(event) => {
                                                            setCarPrice_per_day_8_20_days(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <p>21 и больше</p>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={1}
                                                        placeholder="21 и больше"
                                                        defaultValue={car.price_per_day_21_days_and_more}
                                                        onChange={(event) => {
                                                            setCarPrice_per_day_21_days_and_more(event.target.value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <h4>Характеристики:</h4>
                                                    {carInfoMap[car.id] && carInfoMap[car.id].map((item, index) => (
                                                        <Row className="mt-4" key={item.number ?? index}>
                                                            <Col md={5} className="p-2">
                                                                <Form.Control
                                                                    defaultValue={item.title}
                                                                    onChange={(e) => changeCarInfo(car.id, 'title', e.target.value, item.number)}
                                                                    placeholder="Введите название свойства"
                                                                />
                                                            </Col>
                                                            <Col md={5} className="p-2">
                                                                <Form.Control
                                                                    defaultValue={item.description}
                                                                    onChange={(e) => changeCarInfo(car.id, 'description', e.target.value, item.number)}
                                                                    placeholder="Введите описание свойства"
                                                                />
                                                            </Col>
                                                            <Col md={2} className="p-2">
                                                                <Button className={"w-100"} onClick={() => removeCarInfo(car.id, item.number)} variant="outline-danger">
                                                                    <FontAwesomeIcon icon={faXmark} />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                    <Button variant="outline-dark" className={"mt-4"} onClick={() => addCarInfo(car.id)}>
                                                        Добавить характеристики
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        </Card.Title>

                                        <Modal.Footer>
                                            <Button variant='outline-success' onClick={() =>
                                                updateDev(car.id, {
                                                    name: carName,
                                                    price: carPrice,
                                                    year: carYear,
                                                    deposit: carDeposit,
                                                    price_per_day_1_2_days: carPrice_per_day_1_2_days,
                                                    price_per_day_3_7_days: carPrice_per_day_3_7_days,
                                                    price_per_day_8_20_days: carPrice_per_day_8_20_days,
                                                    price_per_day_21_days_and_more: carPrice_per_day_21_days_and_more,
                                                    images: carFile,
                                                    info: carInfoMap,
                                                })
                                            }
                                            >
                                                Принять изменения
                                            </Button>
                                            <Button variant={"outline-danger"} onClick={() => handleDelete(car.id)}>Удалить товар</Button>
                                        </Modal.Footer>
                                    </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Container>
        </Modal>
    );
});


export default CreateCar;